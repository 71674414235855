import * as Yup from "yup";

const AdjustCompOffSchema = Yup.object().shape({
  start_date: Yup.date().required("Start date is required"),
  end_date: Yup.date().required("End date is required"),
  compoff_id: Yup.number().required("Please select Comp Off date"),
  message: Yup.string().required("Please enter the message"),
});

export default AdjustCompOffSchema;
