import Api from "../../axios";

export default async function apiAdjustCompOff({
  application_id,
  compoff_id,
  reason,
}) {
  return await Api.post(`/adjust_compoff`, {
    application_id,
    compoff_id,
    reason,
  });
}
