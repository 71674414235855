import { useQuery } from "@tanstack/react-query";
import { getCompOffRequest } from "../../../../services/manager/compOff/apiCompOffRequest";
import { usDateFormate } from "../../../../hooks/dateFormat";

export default function CompOffRequestQuery() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["GetCompOff"],
    queryFn: () => getCompOffRequest(),
  });

  // console.log("CompOffRequestQuery data", data?.data);

  const compOffList = data?.data.map((ele, idx) => {
    return {
      id: idx,
      col1: ele.employee_name,
      col2: usDateFormate(ele.date),
      col3: ele.duration,
      col4: ele.description,
      col5: {
        compoff_id: ele.compoff_id,
        status: ele.status,
      },
    };
  });
  return { compOffList, isLoading, refetch };
}
