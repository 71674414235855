import { useQuery } from "@tanstack/react-query";
import apiSummaryReport from "../../../services/attendance/apiSummaryReport";

export default function SummaryReportQuery(employeeId, startDate, endDate) {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["summaryReport", employeeId, startDate, endDate],
    queryFn: () => apiSummaryReport(employeeId, startDate, endDate),
  });

  // console.log("data", data?.data);

  const summaryReport = data?.data.map((summary, idx) => ({
    id: idx,
    col1: summary.employee_name,
    col2: summary["month_&_year"],
    col3: summary.total_days,
    col4: summary.total_working_days,
    col5: summary.days_present,
    col6: summary.off_days,
    col7: summary.cl_taken || 0,
    col8: 0, // medical
    col9: summary.weekend_working,
    col10: summary.average_hours_per_day,
  }));

  return { summaryReport, isLoading, refetch };
}
