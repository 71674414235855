export const breakOptions = [
  { value: "", label: "Select" },
  { value: "5", label: ">5" },
];

export const teamOptions = [
  { value: "UI", label: "UI" },
  { value: "IOS", label: "IOS" },
  { value: "Android", label: "Android" },
  { value: "PHP", label: "PHP" },
  { value: ".NET", label: ".NET" },
  { value: "Python", label: "Python" },
  { value: "Other", label: "Other" },
];

export const employeeStatusOptions = [
  { value: "all", label: "All" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const avgWorkHoursOptions = [
  { value: "all", label: "Select" },
  { value: "8.5", label: "8.5" },
  { value: "9", label: "9" },
  { value: "9.5", label: "9.5" },
  { value: "10", label: "10" },
  { value: "10.5", label: "10.5" },
  { value: "11", label: "11" },
  { value: "11.5", label: "11.5" },
  { value: "12", label: "12" },
];
