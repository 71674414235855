import {
  Box,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useResponsive } from "../../../../hooks/customUseResponsive";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addFeedbackSchema } from "../../../../schemas/addFeedback";
import { RHFSelect } from "../../../../components/RFH/RFHselect";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { removeHtmlTagsAndEntities } from "../../../../hooks/removehtmlTags";
import { postEmployeeFeedback } from "../../../../services/manager/EmployeeFeedback/apiAddFeedback";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { RHFSingleCheckbox } from "../../../../components/RFH/RFHCheckbox";
import { isManager } from "../../../../utils/helpers";


export default function AddFeedbackModal({
  openProp,
  handleClose,
  refetch,
  employeeId,
}) {
  const isMobile = useResponsive("down", "sm");
  const queryClient = useQueryClient();

  const employeeUnderManager = queryClient.getQueryData([
    "employeeUnderManager",
  ]);

  // console.log("AddFeedbackModal employeeUnderManager", employeeUnderManager);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 330 : 808,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    scrollY: "scroll",
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }], // Add color and background color options
      ["link"],
      ["clean"],
    ],
  };
  const defaultValues = {
    employee_id: employeeId,
    feedback: "",
    is_publish: false,
  };

  const methods = useForm({
    resolver: yupResolver(addFeedbackSchema),
    defaultValues,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: postEmployeeFeedback,
    onSuccess: (data) => {
      toast.success(data.data.message);
      refetch();
      methods.reset();
      handleClose();
    },
    onError: (data) => {
      console.error("error employee feedback", data);
    },
  });

  const onSubmit = (formData) => {
    mutate({ ...formData });
  };

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>
                Employee Feedback
              </Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>

            <Box className={styles.container}>
              <Stack className={styles.contSelect}>
                <Box className={styles.flexItem}>
                  <Typography className={styles.label}>
                    Employee Name
                  </Typography>
                  <RHFSelect name="employee_id">
                    {employeeUnderManager?.data.map((ele, idx) => {
                      return (
                        <MenuItem value={ele.employee_id} key={idx}>
                          {ele.name}
                        </MenuItem>
                      );
                    })}
                  </RHFSelect>
                </Box>
              </Stack>
              <Typography className={styles.label}>Feedback</Typography>
              <Box className={styles.textEditorCont}>
                <ReactQuill
                  value={methods.getValues("feedback")}
                  onChange={(value) =>
                    methods.setValue(
                      "feedback",
                      removeHtmlTagsAndEntities(value).length === 0 ? "" : value
                    )
                  }
                  modules={modules}
                  style={{ height: "170px" }}
                />
              </Box>
              <Typography className={styles.feedbackError}>
                {methods.formState.errors.feedback?.message}
              </Typography>

              <Stack className={styles.btnCont}>
                <RHFSingleCheckbox
                  name="is_publish"
                  label="Publish"
                  disabled={isManager() ? true : false}
                />
                <LoadingButton
                  loading={isPending}
                  type="submit"
                  className={styles.btnClass}
                  onClick={() => ""}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
