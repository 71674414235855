import * as Yup from "yup";

const AddCompOff = Yup.object().shape({
  employee_id: Yup.string().required("Please select employee."),
  date: Yup.date().required("Please select date."),
  duration: Yup.string().required("Please select duration"),
  description: Yup.string().required("Please enter description."),
});

export default AddCompOff;
