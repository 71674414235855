import { useQuery } from "@tanstack/react-query"
import { getFinancialYear } from "../../../services/leave/apiFinancialYear"

export const FinancialYearQuery =()=>{
    const {data, isLoading, isError} = useQuery({
        queryKey: ['financialYear'],
        queryFn: ()=>getFinancialYear()
    })

    let FinancialYear = data?.data

    return {FinancialYear, isLoading}
}