import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useQueryClient } from "@tanstack/react-query";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../../../components/DatePickerComp/DatePickerComp";
import RHFTextField from "../../../../components/RFH/RFHtextfield.js";
import { RHFSelect } from "../../../../components/RFH/RFHselect.js";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { useResponsive } from "../../../../hooks/customUseResponsive.js";
import AddAttendanceSchema from "../../../../schemas/addAttendanceSchema.js";
import RHFRadioGroup from "../../../../components/RFH/RFHradioGroup.js";
import RHFTimePicker from "../../../../components/RFH/RFHtimePicker.js";
import { postAddAttendanceOfEmployee } from "../../../../services/manager/attendance/apiAddAttendanceOfEmployee.js";
// import { localStorageInstance } from "../../../../utils/localStorage.js";

export default function AddAttendanceModal({ openProp, handleClose, listOfEmp, employeeId }) {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const isMobile = useResponsive("down", "sm");
  // console.log("list of employee under manager", listOfEmp);
  // let empId = localStorageInstance.getItem("user").employee_id;
  // console.log("current employee", empId);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 330 : 440,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const defaultValues = {
    application_type: "in",
    employee_id: employeeId,
  };
  const methods = useForm({
    resolver: yupResolver(AddAttendanceSchema),
    defaultValues,
  });

  const selectedType = methods.watch("application_type");

  const options = [
    { value: "in", label: "In" },
    { value: "out", label: "Out" },
    { value: "in-out", label: "In-Out" },
  ];

  const onSubmit = (formData) => {
    // console.log("formData === ", formData);
    setLoading(true);

    postAddAttendanceOfEmployee({
      ...formData,
      start_date: `${new Date(formData.start_date).getFullYear()}-${
        new Date(formData.start_date).getMonth() + 1 < 10
          ? "0" + (new Date(formData.start_date).getMonth() + 1)
          : new Date(formData.start_date).getMonth() + 1
      }-${
        new Date(formData.start_date).getDate() < 10
          ? "0" + new Date(formData.start_date).getDate()
          : new Date(formData.start_date).getDate()
      }`,
      application_type: "Missing Entry",
      in_time: formData.in_time
        ? `${new Date(formData.in_time).getHours()}:${new Date(
            formData.in_time
          ).getMinutes()}:${new Date(formData.in_time).getSeconds()}`
        : "",
      out_time: formData.out_time
        ? `${new Date(formData.out_time).getHours()}:${new Date(
            formData.out_time
          ).getMinutes()}:${new Date(formData.out_time).getSeconds()}`
        : "",
    })
      .then((res) => {
        // console.log("add employee attendance == ", res);
        toast.success(res.data.message);
        setLoading(false);
        handleClose();
        queryClient.invalidateQueries({
          queryKey: ["attendanceRequest"],
        });
      })
      .catch((err) => {
        console.err("add employee attendance error == ", err);
        setLoading(false);
      });
  };

  // console.log("errors === ", methods.formState.errors);
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>
                Add Employee Attendance
              </Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              {listOfEmp && (
                <Box>
                  <Typography className={styles.label}>
                    Select Employee
                  </Typography>
                  <RHFSelect name="employee_id">
                    {listOfEmp.map((ele, idx) => {
                      return (
                        <MenuItem value={ele.employee_id} key={idx}>
                          {ele.name}
                        </MenuItem>
                      );
                    })}
                  </RHFSelect>
                </Box>
              )}
              <Box>
                <Typography className={styles.label}>Date</Typography>
                <Stack className={styles.inputFeild} direction="row">
                  <DatePickerComponent name="start_date" isFullWidth />
                </Stack>
              </Box>

              <Box>
                <Stack ml={1} className={styles.inputFeild} direction="row">
                  <RHFRadioGroup
                    options={options}
                    name="application_type"
                    row={true}
                  />
                </Stack>
              </Box>
              {/* <Stack direction="row" spacing={2}> */}
              {(selectedType === "in" || selectedType === "in-out") && (
                <Box>
                  <Typography className={styles.label}>In Time</Typography>
                  <RHFTimePicker name="in_time" />
                </Box>
              )}

              {(selectedType === "out" || selectedType === "in-out") && (
                <Box>
                  <Typography className={styles.label}>Out Time</Typography>
                  <RHFTimePicker name="out_time" />
                </Box>
              )}
              {/* </Stack> */}
              <Box>
                <Typography className={styles.label}>Message</Typography>
                <RHFTextField
                  name="reason"
                  multiline
                  rows={4}
                  fullWidth
                  className={styles.textarea}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#fff !important",
                    },
                  }}
                />
              </Box>
              <Stack alignItems="end">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  className={styles.btn}
                >
                  Apply
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
