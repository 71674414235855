import { useState } from "react";
import { NavLink } from "react-router-dom";
import { DateRange } from "react-date-range";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Loader from "../../../components/Loader";
import { formatDate, humanizeDateDifference } from "../../../utils/helpers";
import styles from "./styles.module.css";
import { breakOptions } from "../../../utils/constants";

// Custom component for the "Last 30 days" header group
function Last30DaysHeader({
  selectedStartDates,
  selectedEndDates,
  setSelectedStartDates,
  setSelectedEndDates,
}) {
  const d1 = new Date();
  const d2 = new Date();
  d2.setDate(d2.getDate() - 30);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState([
    {
      startDate: d2,
      endDate: d1,
      key: "selection",
    },
  ]);
  const [tableText, settableText] = useState("Last 30 days");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedStartDates(formatDate(state[0].startDate));
    setSelectedEndDates(formatDate(state[0].endDate));
    settableText(humanizeDateDifference(state[0].startDate, state[0].endDate));
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
      <Box>
        <Typography variant="body2" fontWeight="bold">
          {tableText}
        </Typography>
        {selectedStartDates && selectedEndDates && (
          <Typography>
            {selectedStartDates} to {selectedEndDates}
          </Typography>
        )}
      </Box>
      <IconButton onClick={handleOpen} size="small">
        <CalendarMonthIcon />
      </IconButton>

      {open && (
        <Menu
          id="lock-menu"
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "center" }}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </Menu>
      )}
    </Box>
  );
}

export default function EmployeeTable({
  report,
  isLoading,
  selectedBreakCount,
  setSelectedBreakCount,
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
}) {
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  return (
    <Box className={styles.dataGridContainer}>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            {/* Group Header */}
            <TableRow>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.pinnedTop,
                  styles.headerBackground,
                  styles.tableText,
                  styles.boldHeader,
                  styles.zIndex,
                ]}
                sx={{ left: 0 }}
                rowSpan={2}
              >
                Employee Info
              </TableCell>
              <TableCell
                className={[
                  // styles.headerCell,
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.boldHeader,
                ]}
                rowSpan={2}
                align="center"
              >
                Manager
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.boldHeader,
                ]}
                rowSpan={2}
                align="center"
              >
                Team
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.boldHeader,
                ]}
                colSpan={4}
                align="center"
              >
                Upcoming Leaves
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.boldHeader,
                ]}
                colSpan={2}
                align="center"
              >
                Pending Attendance
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.boldHeader,
                ]}
                rowSpan={2}
                align="center"
              >
                Comp Off
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.boldHeader,
                ]}
                colSpan={5}
                align="center"
              >
                <Last30DaysHeader
                  selectedStartDates={selectedStartDate}
                  selectedEndDates={selectedEndDate}
                  setSelectedStartDates={setSelectedStartDate}
                  setSelectedEndDates={setSelectedEndDate}
                />
              </TableCell>
            </TableRow>

            {/* Column Header */}
            <TableRow>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.subHeader,
                ]}
                align="right"
              >
                P
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.subHeader,
                ]}
                align="right"
              >
                A
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.subHeader,
                ]}
                align="right"
              >
                R
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.subHeader,
                ]}
                align="right"
              >
                LWP
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.subHeader,
                ]}
                align="right"
              >
                In/Out
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.subHeader,
                ]}
                align="right"
              >
                WFH
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.subHeader,
                ]}
                align="right"
              >
                Short Days
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.subHeader,
                ]}
                align="right"
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <span>Breaks</span>
                  <select
                    onChange={(event) => {
                      // console.log("Selected:", event.target.value);
                      setSelectedBreakCount(event.target.value);
                    }}
                    value={selectedBreakCount}
                  >
                    {breakOptions.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>
                </Box>
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.subHeader,
                ]}
                align="right"
              >
                Avg. Hours
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.subHeader,
                ]}
                align="right"
              >
                Late
              </TableCell>
              <TableCell
                className={[
                  styles.tableCell,
                  styles.headerBackground,
                  styles.tableText,
                  styles.subHeader,
                ]}
                align="right"
              >
                Leave Taken
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading && (
              <Stack className={styles.loaderCont}>
                <Loader />
              </Stack>
            )}
            {report &&
              report
                .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                .map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell
                      className={[
                        styles.tableCell,
                        styles.pinnedLeft,
                        styles.rowBackground,
                        styles.tableText,
                      ]}
                    >
                      <NavLink
                        to={`/employee/${row.col1.employee_id}`}
                        target="_blank"
                        className={styles.link}
                      >
                        {row.col1.name}
                      </NavLink>
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col2}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col3}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col4}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col5}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col6}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col7}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col8}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col9}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col10}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col11}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col12}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col13}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col14}
                    </TableCell>
                    <TableCell className={[styles.tableCell, styles.tableText]}>
                      {row.col15}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        rowsPerPage={rowPerPage}
        count={report?.length || 0}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
