import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { ManualListQuery } from "../../utils/reactQuery/attendance/ManualList";
import { useResponsive } from "../../hooks/customUseResponsive";
import { localStorageInstance } from "../../utils/localStorage";
import TotalHourQuery from "../../utils/reactQuery/attendance/TotalHours";
import { InOutMissingEntry } from "../../utils/reactQuery/manager/count/InoutMissingEntry";
import { TotalHoursMissingEntry } from "../../utils/reactQuery/manager/count/TotalHoursMissingEntry";
import { DataGrid } from "@mui/x-data-grid";
import viewIcon from "../../styles/icons/viewicon.svg";
import NotesPopup from "../NotesPopup/NotesPopup";
import Iconify from "../Icon/Iconify";
import {
  addTotalTime,
  addZeroToSingleDigits,
  checkLateComing,
  getTimeDifferenceTillCurrentTime,
} from "../../utils/helpers";
import BreakCountQuery from "../../utils/reactQuery/BreakCount";
import forwardIcon from "../../styles/icons/forward.svg";
import backwardIcon from "../../styles/icons/back.svg";
import dayjs from "dayjs";
import { usDateFormate } from "../../hooks/dateFormat";
import { PersonalInfoQuery } from "../../utils/reactQuery/profile/PersonalInfo";

export default function InOutModal({
  openProp,
  handleClose,
  date: initialDate,
  day,
  empId,
}) {
  const isMobile = useResponsive("down", "sm");
  const [date, setDate] = useState(initialDate);
  const [selectedId, setSelectedId] = useState();
  const [reasonModal, setReasonModal] = useState();
  const { breakCount, breakCountRefetch } = BreakCountQuery(date, empId);
  const userDetails = localStorageInstance.getItem("user");
  const { employee } = PersonalInfoQuery(empId);
  // console.log("employee", employee);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 330 : 592,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    // maxHeight: '90vh',
    // overflowX: 'auto'
  };

  // if (empId) {
  //   var { list, isLoading } = InOutMissingEntry({ id: empId, date: date });
  //   var { totalHours } = TotalHoursMissingEntry({ id: empId, date: date });
  // } else {
  //   var { list: listManual, isLoading: manualIsLoading } = ManualListQuery({
  //     date,
  //   });
  //   var { totalHours: manualTotalHours } = TotalHourQuery({ date });
  // }
  let list,
    isLoading,
    inOutMissingEntryRefetch = useCallback(() => {}, []),
    totalHours,
    totalHoursRefetch = useCallback(() => {}, []);
  let listManual,
    manualIsLoading,
    manualListRefetch = useCallback(() => {}, []),
    manualTotalHours,
    manualTotalHoursRefetch = useCallback(() => {}, []);
  let initialRows = [];

  if (empId) {
    // console.log("empId available");
    // Call the queries when empId is available
    ({
      list,
      isLoading,
      refetch: inOutMissingEntryRefetch,
    } = InOutMissingEntry({
      id: empId,
      date: date,
    }));

    ({ totalHours, refetch: totalHoursRefetch } = TotalHoursMissingEntry({
      id: empId,
      date: date,
    }));

    initialRows = list
      ? list?.map((ele, idx, arr) => {
          // console.log("check in time", ele.check_in_time);
          let timeDiff = "";
          // if (!ele.check_out_time) {
          if (!ele.check_out_time && arr[idx + 1] === undefined) {
            // timeDiff = getTimeDifferenceTillCurrentTime(ele.check_in_time);
            timeDiff = getTimeDifferenceTillCurrentTime(
              arr[idx + 1] !== undefined
                ? arr[idx + 1].check_in_time
                : ele.check_in_time
            );
            // console.log("totalHours", totalHours);
            // console.log("timeDiff", timeDiff);
            totalHours = addTotalTime(totalHours, timeDiff);
          }
          return {
            id: idx,
            col1: {
              checkInTime: ele.check_in_time,
              lateComing: checkLateComing(ele.check_in_time),
            },
            col2: ele.check_out_time ? ele.check_out_time : "-",
            col3: {
              time: !ele.check_out_time ? timeDiff ? timeDiff : "0:00" : ele.total_time,
              isCalculated: timeDiff ? true : false,
              isManual: ele.is_manual,
              appId: ele.attendance_id,
            },
          };
        })
      : [];
  } else {
    // console.log("empId not available");
    // Call the queries when empId is not available
    ({
      list: listManual,
      isLoading: manualIsLoading,
      refetch: manualListRefetch,
    } = ManualListQuery({
      date,
    }));
    ({ totalHours: manualTotalHours, refetch: manualTotalHoursRefetch } =
      TotalHourQuery({ date }));

    initialRows = listManual
      ? listManual?.map((ele, idx, arr) => {
          // console.log("manual check in time", typeof ele.check_in_time);
          let timeDiff = "";
          // console.log(
          //   "missing out time and no further entries",
          //   !ele.check_out_time && arr[idx + 1] === undefined
          // );
          if (!ele.check_out_time && arr[idx + 1] === undefined) {
            // console.log("arr", arr[idx + 1] !== undefined);
            // console.log(
            //   "check in time",
            //   arr[idx + 1] !== undefined
            //     ? arr[idx + 1].check_in_time
            //     : ele.check_in_time
            // );
            timeDiff = getTimeDifferenceTillCurrentTime(
              arr[idx + 1] !== undefined
                ? arr[idx + 1].check_in_time
                : ele.check_in_time
            );
            // console.log("manualTotalHours", manualTotalHours);
            manualTotalHours = addTotalTime(manualTotalHours, timeDiff);
          }

          return {
            id: idx,
            col1: {
              checkInTime: ele.check_in_time,
              lateComing: checkLateComing(ele.check_in_time),
            },
            col2: ele.check_out_time ? ele.check_out_time : "-",
            col3: {
              time: !ele.check_out_time ? timeDiff ? timeDiff : "0:00" : ele.total_time,
              isCalculated: timeDiff ? true : false,
              isManual: ele.is_manual,
              appId: ele.attendance_id,
            },
          };
        })
      : [];
  }

  // console.log("list == ", list, listManual);

  useEffect(() => {
    if (openProp) {
      inOutMissingEntryRefetch();
      totalHoursRefetch();
      manualListRefetch();
      manualTotalHoursRefetch();
      breakCountRefetch();
    }
  }, [
    openProp,
    date,
    breakCountRefetch,
    inOutMissingEntryRefetch,
    totalHoursRefetch,
    manualListRefetch,
    manualTotalHoursRefetch,
  ]);

  const columns = [
    {
      field: "col1",
      headerName: "In Time",
      minWidth: 100,
      flex: 1.4,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value.checkInTime}</span>,
      cellClassName: (value) => {
        // console.log("param", value);
        return value.id === 0 && value.value.lateComing
          ? "late-coming-cell"
          : null;
      },
    },
    {
      field: "col2",
      headerName: "Out Time",
      minWidth: 100,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Total Time",
      minWidth: 100,
      sortable: false,
      flex: 1,
      renderCell: (value) => (
        <Stack className={styles.totalTimeCont}>
          <Typography className={styles.totalTime}>
            {value.value.time}
          </Typography>
          {value.value.isManual && (
            <img
              className={styles.icon}
              src={viewIcon}
              alt="view icon"
              onClick={() => handleView(value.value.appId)}
            />
          )}
        </Stack>
      ),
      cellClassName: (value) => {
        // console.log("param", value);
        return value.value.isCalculated ? "calculated-total-time-cell" : null;
      },
    },
  ];

  const handleView = (id) => {
    setSelectedId(id);
    setReasonModal(true);
  };

  const handleLeftClick = () => {
    // console.log("date", dayjs(date));
    // console.log("prev date", dayjs(date).subtract(1, "d"));
    const prevDate = dayjs(date).subtract(1, "d");
    // console.log("prev date", prevDate.format("YYYY-MM-DD"));
    setDate(prevDate.format("YYYY-MM-DD")); // Update the date state
  };
  const handleRightClick = () => {
    // console.log("date", dayjs(date));
    // console.log("next date", dayjs(date).add(1, "d"));
    const nextDate = dayjs(date).add(1, "d");
    // console.log("next date", nextDate.format("YYYY-MM-DD"));
    setDate(nextDate.format("YYYY-MM-DD")); // Update the date state
  };

  // console.log("date", date);
  const modalDate = usDateFormate(date); // date?.split("-");
  // console.log("modalDate", modalDate);

  // console.log("manual list ----", initialRows);
  // console.log("manualTotalHours Again", manualTotalHours);

  return (
    <Modal open={openProp} onClose={handleClose} sx={{ padding: "20px" }}>
      <Box sx={{ ...style }}>
        <Stack direction="row" className={styles.header} alignItems="center">
          <Typography className={styles.heading}>
            {empId ? employee?.name : userDetails?.name}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
            width={200}
          >
            <Button
              className={styles.modalDate}
              variant="contained"
              startIcon={<Iconify icon="circum:calendar" width={24} />}
            >
              {modalDate}
            </Button>
            <Box onClick={() => handleClose()}>
              <CloseIcon className={styles.icon} />
            </Box>
          </Box>
        </Stack>
        {isLoading || manualIsLoading ? (
          <Stack className={styles.progressBar}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              marginBottom={2}
            >
              {/* Left Button */}
              <Button
                variant="outlined"
                className={`${styles.arrowBtn} ${styles.arrowBtnLeft}`}
                onClick={handleLeftClick}
              >
                <img
                  className={styles.icons}
                  src={backwardIcon}
                  alt="backward"
                />
              </Button>

              {/* DataGrid in the middle */}
              <Box
                className={styles.datagridCont}
                sx={{ height: initialRows.length > 7 && 480, flexGrow: 1 }}
              >
                <DataGrid
                  columns={columns}
                  rows={initialRows}
                  disableColumnMenu
                  getRowClassName={(params) =>
                    params.row.disabled
                      ? "MuiDataGrid-row disabled"
                      : "MuiDataGrid-row disabled"
                  }
                  initialState={{
                    pagination: { paginationModel: { pageSize: 99 } },
                  }}
                  showCellVerticalBorder={true}
                  showColumnVerticalBorder={true}
                  autoHeight={initialRows.length > 7 ? false : true}
                  hideFooter={true}
                  sx={{
                    border: 0,
                    "& .MuiDataGrid-main": {
                      border: "1px solid #CED4DA",
                      borderRadius: "4px",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                      display: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      fontSize: "14px !important",
                      fontWeight: "400 !important",
                      color: "#455F69",
                    },
                    "& .late-coming-cell": {
                      backgroundColor: "mistyrose",
                    },
                    "& .calculated-total-time-cell": {
                      backgroundColor: "lightgray",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      height: "48px !important",
                      backgroundColor: "#F5F8FC",
                      color: "#455F69",
                      paddingLeft: "10px",
                      paddingRight: "0px",
                      borderBottom: "1px solid #CED4DA",
                    },
                    "& .MuiDataGrid-footerContainer": {
                      border: "none",
                    },
                    "& .MuiDataGrid-row:focus, & .MuiDataGrid-cell:focus": {
                      outline: "none",
                    },
                    "& .MuiDataGrid-row.Mui-selected.Mui-selected:hover": {
                      backgroundColor: "transparent",
                    },
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: "transparent",
                    },
                    "& .MuiDataGrid-row.Mui-selected": {
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                />
              </Box>

              {/* Right Button */}
              <Button
                variant="outlined"
                className={`${styles.arrowBtn} ${styles.arrowBtnRight}`}
                onClick={handleRightClick}
              >
                <img className={styles.icons} src={forwardIcon} alt="forward" />
              </Button>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              paddingX={4}
              paddingBottom={1}
              className={styles.modalFooter}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body1"># of Breaks:&nbsp;</Typography>
                <Typography variant="body1" fontWeight={700}>
                  {addZeroToSingleDigits(breakCount)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body1">Total Time:&nbsp;</Typography>
                <Typography variant="body1" fontWeight={700}>
                  {empId ? totalHours : manualTotalHours}
                </Typography>
              </Box>
            </Stack>
          </>
        )}
        {reasonModal && (
          <NotesPopup
            openPop={reasonModal}
            handleClose={() => setReasonModal(false)}
            id={selectedId}
            manual={true}
          />
        )}
      </Box>
    </Modal>
  );
}
