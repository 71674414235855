import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import styles from "./editNotice.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { useResponsive } from "../../hooks/customUseResponsive";
import { ViewNotice } from "../../utils/reactQuery/notice/ViewNotice";
import EditNoticeSchema from "../../schemas/editNotice";
import { updateNotice } from "../../services/notice/apiUpdateNotice";
import Loader from "../Loader";

export default function UpdateNotice({ openProp, handleClose, id }) {
  const isMobile = useResponsive("down", "sm");
  // console.log("id", id);
  const { note, isLoading, refetch } = ViewNotice({ id });
  // console.log("note ===", note);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 330 : 808,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    scrollY: "scroll",
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }],
      ["link"],
      ["clean"],
    ],
  };
  const defaultValues = {
    description: note?.description,
  };

  const methods = useForm({
    resolver: yupResolver(EditNoticeSchema),
    defaultValues,
  });

  useEffect(() => {
    methods.setValue("description", note?.description);
  }, [methods, note?.description]);

  const { mutate, isPending } = useMutation({
    mutationFn: updateNotice,
    onSuccess: (data) => {
      toast.success(data.data.message);
      methods.reset();
      handleClose();
      refetch();
    },
    onError: (data) => {
      console.error("error updating notice", data);
      toast.error(
        "Something went wrong while updating the notice.\nPlease try again."
      );
    },
  });

  // console.log("errors === ", methods.formState.errors);

  const onSubmit = (formData) => {
    // console.log("formData === ", formData);
    mutate({ policy_id: id, description: formData.description });
  };

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>Edit Notice</Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>

            <Box className={styles.container}>
              <Stack className={styles.contSelect}></Stack>
              <Typography className={styles.label}>
                Modify Notice Content
              </Typography>
              {isLoading ? (
                <Loader />
              ) : (
                <Box className={styles.textEditorCont}>
                  <ReactQuill
                    value={note?.description}
                    onChange={(value) => methods.setValue("description", value)}
                    modules={modules}
                    style={{ height: "170px" }}
                  />
                </Box>
              )}
              <Typography className={styles.feedbackError}>
                {methods.formState.errors.description?.message}
              </Typography>
              <Stack className={styles.btnCont}>
                <LoadingButton
                  loading={isPending}
                  type="submit"
                  className={styles.btnClass}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </LoadingButton>

                <Button
                  className={styles.btnClass}
                  onClick={() => handleClose()}
                  variant="outlined"
                  color="primary"
                >
                  Close
                </Button>
              </Stack>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
