import { useQuery } from "@tanstack/react-query";
import { getViewMessageLeave } from "../../../services/manager/apiViewMessageLeave";
import toast from "react-hot-toast";

export default function ViewMessageLeave(id){
    const {data, isLoading, error} = useQuery({
        queryKey: ['viewMessageLeave',id],
        queryFn: ()=>getViewMessageLeave({id})
    })

    if(error){
        console.error("object", error.response.data.message);
        toast.error(error.response.data.message)
    }

    const reason = data?.data[0].reason

    return {reason,isLoading}
}