import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useResponsive } from "../../../../hooks/customUseResponsive";
import { FormProvider, useForm } from "react-hook-form";
import DatePickerComponent from "../../../../components/DatePickerComp/DatePickerComp";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFSelect } from "../../../../components/RFH/RFHselect";
import RHFTextField from "../../../../components/RFH/RFHtextfield";
import AdjustCompOffSchema from "../../../../schemas/adjustCompOffSchema";
import dayjs from "dayjs";
import { useMutation } from "@tanstack/react-query";
import apiAdjustCompOff from "../../../../services/manager/compOff/apiAdjustCompOff";
import toast from "react-hot-toast";

export default function AdjustCompOff({
  openProp,
  handleClose,
  dates,
  leaveId,
  refetch,
  availableCompOffs,
  refetchCompOff,
}) {
  const [loading, setLoading] = useState(false);

  const isMobile = useResponsive("down", "sm");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 330 : 550,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const defaultValues = {
    start_date: dayjs(dates.startDate),
    end_date: dayjs(dates.endDate),
    compoff_id: null,
    message: "",
  };

  const methods = useForm({
    resolver: yupResolver(AdjustCompOffSchema),
    defaultValues,
  });

  const { mutate } = useMutation({
    mutationKey: "AdjustCompOff",
    mutationFn: apiAdjustCompOff,
    onSuccess: (data) => {
      // console.log("success data", data);
      toast.success(data.data.message);
    },
    onError: (err) => {
      console.error("error", err);
      toast.error(err?.response?.data?.message);
    },
    onSettled: () => {
      setLoading(false);
      handleClose();
      refetch();
    },
  });

  const onSubmit = (formdata) => {
    setLoading(true);
    // console.log("leaveId", leaveId);
    // console.log("formdata", formdata);
    mutate({
      application_id: leaveId,
      compoff_id: formdata.compoff_id,
      reason: formdata.message,
    });
  };

  useEffect(() => {
    refetchCompOff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>
                Adjust Comp Off
              </Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              <Box>
                <Typography className={styles.label}>From</Typography>
                <Stack className={styles.inputFeild} direction="row">
                  <DatePickerComponent
                    name="start_date"
                    isFullWidth={true}
                    disabled={true}
                  />
                </Stack>
              </Box>

              <Box>
                <Typography className={styles.label}>To</Typography>
                <Stack className={styles.inputFeild} direction="row">
                  <DatePickerComponent
                    name="end_date"
                    isFullWidth={true}
                    disabled={true}
                  />
                </Stack>
              </Box>

              <Box>
                <Typography className={styles.label}>
                  Available Comp Off
                </Typography>
                <RHFSelect name="compoff_id">
                  {availableCompOffs?.map((compoff, idx) => (
                    <MenuItem key={idx} value={compoff.id}>
                      {compoff.date}&nbsp; ({compoff.day})&nbsp;
                      {compoff.type}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Box>

              <Box>
                <Typography className={styles.label}>Message</Typography>
                <RHFTextField
                  name="message"
                  multiline
                  rows={4}
                  fullWidth
                  className={styles.textarea}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#fff !important",
                    },
                  }}
                />
              </Box>
              <Stack alignItems="end">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  className={styles.btn}
                >
                  Confirm
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
