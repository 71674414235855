import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useResponsive } from "../../hooks/customUseResponsive";

export default function LeaveLogPopup({ openPop, handleClose, note }) {
  const isMobile = useResponsive("down", "sm");

  // console.log("note ===", note);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 330 : 507,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    maxHeight: "90vh", // Ensures the modal doesn't exceed the viewport height
    overflowY: "auto",
  };

  return (
    <Modal open={openPop} onClose={() => handleClose()}>
      <Box sx={{ ...style }}>
        <Stack direction="row" className={styles.header}>
          <Typography className={styles.heading}>Notes</Typography>
          <Box onClick={() => handleClose()}>
            <CloseIcon className={styles.icon} />
          </Box>
        </Stack>
        {
          <Box>
            <Typography className={styles.noteDescription}>{note}</Typography>
          </Box>
        }
      </Box>
    </Modal>
  );
}
