import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@tanstack/react-query";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../../../components/DatePickerComp/DatePickerComp";
import RHFTextField from "../../../../components/RFH/RFHtextfield.js";
import { RHFSelect } from "../../../../components/RFH/RFHselect.js";
import { LoadingButton } from "@mui/lab";
import { useResponsive } from "../../../../hooks/customUseResponsive.js";
import RHFRadioGroup from "../../../../components/RFH/RFHradioGroup.js";
import AddCompOff from "../../../../schemas/addCompoff.js";
import toast from "react-hot-toast";
import { addCompOffForEmployee } from "../../../../services/manager/compOff/apiAddCompoff.js";
import { formatDate } from "../../../../utils/helpers.js";

const availableCompOffDurations = [
  {
    label: "Full Day",
    value: "full_day",
  },
  {
    label: "Half Day",
    value: "half_day",
  },
];

export default function AddCompOffModal({
  openProp,
  handleClose,
  listOfEmp,
  refetch,
}) {
  const [loading, setLoading] = useState(false);
  const isMobile = useResponsive("down", "sm");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 330 : 550,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const defaultValues = {
    employee_id: "",
    date: null,
    duration: "",
    description: "",
  };
  const methods = useForm({
    resolver: yupResolver(AddCompOff),
    defaultValues,
  });

  const { mutate } = useMutation({
    mutationKey: "AddCompoff",
    mutationFn: addCompOffForEmployee,
    onSuccess: (data) => {
      // console.log("success data", data);
      toast.success(data?.data.message);
      handleClose();
      refetch();
    },
    onError: (err) => {
      console.error("error message", err);
      toast.error(err?.response?.data?.message);
    },
  });

  const onSubmit = (formData) => {
    // console.log("formData === ", formData);
    setLoading(true);
    mutate({
      ...formData,
      date: formatDate(formData.date),
      compoff_type: formData.duration,
    });
    setLoading(false);
  };

  // console.log("errors === ", methods.formState.errors);
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>Add Comp Off</Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              <Box>
                <Typography className={styles.label}>
                  Select Employee
                </Typography>
                <RHFSelect name="employee_id">
                  {listOfEmp?.map((ele, idx) => {
                    return (
                      <MenuItem value={ele.employee_id} key={idx}>
                        {ele.name}
                      </MenuItem>
                    );
                  })}
                </RHFSelect>
              </Box>

              <Box>
                <Typography className={styles.label}>Date</Typography>
                <Stack className={styles.inputFeild} direction="row">
                  <DatePickerComponent name="date" isFullWidth={true} />
                </Stack>
              </Box>

              <Box>
                <RHFRadioGroup
                  name="duration"
                  options={availableCompOffDurations}
                  row={true}
                />
              </Box>

              <Box>
                <Typography className={styles.label}>Description</Typography>
                <RHFTextField
                  name="description"
                  multiline
                  rows={4}
                  fullWidth
                  className={styles.textarea}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#fff !important",
                    },
                  }}
                />
              </Box>
              <Stack alignItems="end">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  className={styles.btn}
                >
                  Confirm
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
