import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import useEmployeeSelection from "../../../hooks/useEmployeeSelection";
import Iconify from "../../../components/Icon/Iconify";
import { RHFMultiCheckbox } from "../../../components/RFH/RFHCheckbox";
import { FormProvider, useForm } from "react-hook-form";
import DropdownPopover from "../../../components/DropDownPopover/DropDownPopover";
import AllEmployeeReportQuery from "../../../utils/reactQuery/manager/employeeDetail/AllEmployeeReport";
import ManagersListQuery from "../../../utils/reactQuery/ManagersList";
import { RHFSelect } from "../../../components/RFH/RFHselect";
import EmployeeTable from "./EmployeeTable";
import {
  avgWorkHoursOptions,
  employeeStatusOptions,
  teamOptions,
} from "../../../utils/constants";

export default function AllEmployees() {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [selectedManager, setSelectedManager] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedWorkHours, setSelectedWorkHours] = useState("");
  const [selectedBreakCount, setSelectedBreakCount] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  // console.log("selectedEmployee", selectedEmployee);
  // console.log("selectedTeam", selectedTeam);
  // console.log("selectedManager", selectedManager);
  // console.log("selectedStatus", selectedStatus);
  // console.log("selectedWorkHours", selectedWorkHours);
  // console.log("selectedBreakCount", selectedBreakCount);
  // console.log("selectedStartDate", selectedStartDate);
  // console.log("selectedEndDate", selectedEndDate);

  const { emplist } = useEmployeeSelection();
  // console.log("AllEmployees emplist", emplist);

  const { managersList } = ManagersListQuery();
  // console.log("managersList", managersList);

  const { report, isLoading } = AllEmployeeReportQuery(
    selectedEmployee?.employee_id || "all",
    selectedTeam,
    selectedManager,
    selectedStatus,
    selectedWorkHours,
    selectedStartDate,
    selectedEndDate,
    selectedBreakCount
  );

  const handleChange = (event, newValue) => {
    setSelectedEmployee(newValue);
  };

  const defaultValues = {
    team: [],
    manager: [],
    status: "Active",
    workingHours: "all",
  };

  const methods = useForm({
    defaultValues,
  });

  const { watch } = methods;

  // Watch specific fields for changes
  const teamValue = watch("team");
  const managerValue = watch("manager");
  const statusValue = watch("status");
  const workingHoursValue = watch("workingHours");

  // Sync form values with state
  useEffect(() => {
    setSelectedTeam(teamValue || []);
  }, [teamValue]);

  useEffect(() => {
    setSelectedManager(managerValue || []);
  }, [managerValue]);

  useEffect(() => {
    setSelectedStatus(statusValue || "");
  }, [statusValue]);

  useEffect(() => {
    // timeExtractor(workingHoursValue);
    setSelectedWorkHours(workingHoursValue || "");
  }, [workingHoursValue]);

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingX={4}
            paddingY={2}
          >
            <Box width={350}>
              <Autocomplete
                options={emplist}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    placeholder="Search..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Iconify
                          icon="ph:magnifying-glass-bold"
                          color="#B9B9B9"
                        />
                      ),
                    }}
                  />
                )}
                value={selectedEmployee}
                onChange={handleChange}
                isOptionEqualToValue={(option, value) =>
                  option.value === value?.employee_id
                }
                fullWidth
              />
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <DropdownPopover buttonLabel="Team">
                <RHFMultiCheckbox name="team" options={teamOptions || []} />
              </DropdownPopover>
              <DropdownPopover buttonLabel="Manager">
                <RHFMultiCheckbox name="manager" options={managersList || []} />
              </DropdownPopover>
              <DropdownPopover buttonLabel="Status">
                <RHFSelect name="status">
                  {employeeStatusOptions.map((opt, idx) => (
                    <MenuItem value={opt.value} key={idx}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </DropdownPopover>
              <DropdownPopover buttonLabel="Avg. Working Hours" isTimer={true}>
                {/* <RHFTimePicker name="workingHours" showAmPm={false} /> */}
                <RHFSelect name="workingHours">
                  {avgWorkHoursOptions.map((opt, idx) => (
                    <MenuItem key={idx} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </DropdownPopover>
            </Box>
          </Box>
        </form>
      </FormProvider>

      <EmployeeTable
        report={report}
        isLoading={isLoading}
        selectedBreakCount={selectedBreakCount}
        setSelectedBreakCount={setSelectedBreakCount}
        selectedStartDate={selectedStartDate}
        setSelectedStartDate={setSelectedStartDate}
        selectedEndDate={selectedEndDate}
        setSelectedEndDate={setSelectedEndDate}
      />
    </>
  );
}
